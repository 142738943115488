import React from "react";
import TableHeader from "../tableheader";
import TableRow from "../tablerow";

class Schedule extends React.Component {

  render() {
    return (
      <table className="table">
        <thead>
          <TableHeader
            day="Day"
            event="Event"
            notes="Notes"
            time="Time"
            where="Location"
          />
        </thead>
        <tbody>

          {/* Thursday */}
          <TableRow
            color="purple"
            day="Thursday"
            event="Arrival & Check-in"
            notes="Paperwork and Orientation"
            time="2:00 pm - 7:00 pm"
            where="The Chapel"
          />

          <TableRow
            color="purple"
            event="Fellowship"
            notes="Supper"
            time="4:00 pm - 6:00 pm"
            where="Dining Hall"
          />

          <TableRow
            color="purple"
            event="[Message]: &quot;How Does God Speak?&quot;"
            notes="Speaker: TBD"
            time="6:30 pm - 7:30 pm"
            where="The Chapel"
          />

          <TableRow
            color="purple"
            event="[Message]: &quot;What Is Scripture Good For?&quot;"
            notes="Speaker: Billy Elmquist"
            time="8:00 pm - 9:00 pm"
            where="The Chapel"
          />

          <TableRow
            color="purple"
            event="Fellowship & Music"
            time="9:00 pm - 10:30 pm"
          />

          {/* Friday */}
          <TableRow
            color="blue"
            day="Friday"
            event="Dining Room Discussions"
            notes="Coffee, Muffins, and Q&amp;A"
            time="6:30 am - 7:30 am"
            where="Dining Hall"
          />

          <TableRow
            color="blue"
            event="Breakfast"
            time="8:00 am - 9:00 am"
            where="Dining Hall"
          />

          <TableRow
            color="blue"
            event="[Message]: &quot;How Real Is It?&quot;"
            notes="Speaker: Bill Barnett"
            time="9:30 am - 10:30 am"
            where="The Chapel"
          />

          <TableRow
            color="blue"
            event="Break"
            notes="Stretch legs"
            time="10:30 am - 10:45 am"
            where="The Chapel"
          />

          <TableRow
            color="blue"
            event="[Message]: &quot;What Is God's Plan for the Body?&quot;"
            notes="Speaker: Caleb Bill"
            time="10:45 am - 12:00 pm"
            where="The Chapel"
          />

          <TableRow
            color="blue"
            event="Lunch"
            time="12:30 pm - 2:00 pm"
            where="Dining Hall"
          />

          <TableRow
            color="blue"
            event="Free Time"
            time="2:00 pm - 4:00 pm"
          />

          <TableRow
            color="blue"
            event="[Message]: Women&apos;s Meeting"
            notes="Speaker: Mary and Eliya"
            time="4:00 pm - 5:00 pm"
            where="The Chapel"
          />

          <TableRow
            color="blue"
            event="Supper"
            time="5:30 pm - 6:30 pm"
          />

          <TableRow
            color="blue"
            event="Message: &apos;What Is Our Predestination?&apos;"
            notes="Speaker: Barney Monroe"
            time="7:00 pm - 8:00 pm"
            where="The Chapel"
          />

          <TableRow
            color="blue"
            event="Fellowship & Music"
            time="8:00 pm - 10:30 pm"
            where="The Chapel"
          />

          {/* Saturday */}
          <TableRow
            color="green"
            day="Saturday"
            event="Dining Room Discussions"
            notes="Coffee, Muffins,  and Q&A"
            time="6:30 am - 8:00 am"
            where="Dining Hall"
          />

          <TableRow
            color="green"
            event="Breakfast"
            time="8:00 am - 9:00 am"
            where="Dining Hall"
          />

          <TableRow
            color="green"
            event="[Message]: Timothy Sessions"
            notes="Speakers: Young Men"
            time="9:30 am - 10:45 am"
            where="The Chapel"
          />

          <TableRow
            color="green"
            event="Break"
            notes="Stretch legs"
            time="10:45 am - 11:00 am"
            where="The Chapel"
          />

          <TableRow
            color="green"
            event="[Message]: Timothy Sessions"
            notes="Speakers: Young Men"
            time="11:00 am - 12:00 pm"
            where="The Chapel"
          />

          <TableRow
            color="green"
            event="Lunch"
            time="12:30 pm - 2:00 pm"
            where="The Chapel"
          />

          <TableRow
            color="green"
            event="Camp Photo"
            notes="Report to Kadee Luethge"
            time="2:15 pm"
            where="Chapel Yard"
          />

          <TableRow
            color="green"
            event="Relay Race"
            notes="Report to Scott Gross"
            time="2:30 pm - 3:30 pm"
            where="Chapel Yard"
          />

          <TableRow
            color="green"
            event="[Message]: Men's Meeting"
            notes="Speaker: Larry Gabbard"
            time="4:30 pm - 5:30 pm"
            where="The Chapel"
          />

          <TableRow
            color="green"
            event="Supper"
            time="5:30 pm - 6:30 pm"
            where="Dining Hall"
          />

          <TableRow
            color="green"
            event="[Message]: &quot;How Do We Raise Children?&quot;"
            notes="Speaker: Marshall Coleman"
            time="7:00 pm - 8:00 pm"
            where="The Chapel"
          />

          <TableRow
            color="green"
            event="Fellowship & Music"
            time="8:00 pm - 11:00 pm"
            where="The Chapel"
          />

          {/* Sunday */}
          <TableRow
            color="red"
            day="Sunday"
            event="Breakfast"
            time="8:00 am - 9:00 am"
            where="Dining Hall"
          />

          <TableRow
            color="red"
            event="[Message]: &apos;What About Works Under Grace?&apos;"
            notes="Speaker: TBD"
            time="9:30 am - 10:45 am"
            where="The Chapel"
          />

          <TableRow
            color="red"
            event="[Message]: &apos;How Do We Prove God's Will?&apos;"
            notes="Speaker: Barney Monroe"
            time="11:00 am - 12:00 pm"
            where="The Chapel"
          />

          <TableRow
            color="red"
            event="Lunch"
            time="12:30 pm - 2:00 pm"
            where="Dining Hall"
          />

          <TableRow
            color="red"
            event="Clean Up"
            notes="Cabins, The Chapel, and Dining Hall"
            time="2:00 pm - 3:30 pm"
            where="All Buildings"
          />

        </tbody>
      </table>
    );
  }

}

export default Schedule;
